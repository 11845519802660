var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page-layout',{attrs:{"title":_vm.$t('time.crud.create_overtime'),"icon":"tw-leave-process"},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"flat":"","disabled":_vm.loading}},[_c('validation-observer',{ref:"form"},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-bottom":45,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('time.overtime_request.date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":_vm.$t('time.overtime_request.date'),"error-messages":errors,"readonly":""},on:{"click:clear":function($event){_vm.beginDate = null}},model:{value:(_vm.beginDate),callback:function ($$v) {_vm.beginDate=$$v},expression:"beginDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"change":function($event){_vm.dateMenu = false}},model:{value:(_vm.beginDate),callback:function ($$v) {_vm.beginDate=$$v},expression:"beginDate"}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('time.overtime_request.workplan'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('workplan-picker',{attrs:{"date":_vm.beginDate,"error-messages":errors},model:{value:(_vm.overtimeDTO.workplan),callback:function ($$v) {_vm.$set(_vm.overtimeDTO, "workplan", $$v)},expression:"overtimeDTO.workplan"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('time.overtime_request.overtime_in_minutes'),"rules":"required|numeric|min_value:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","type":"number","min":10,"label":_vm.$t('time.overtime_request.overtime_in_minutes'),"error-messages":errors,"suffix":_vm.$t('time.overtime_request.short_minute')},model:{value:(_vm.overtimeDTO.overtimeInMinutes),callback:function ($$v) {_vm.$set(_vm.overtimeDTO, "overtimeInMinutes", _vm._n($$v))},expression:"overtimeDTO.overtimeInMinutes"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('time.overtime_request.period'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('overtime-period-picker',{attrs:{"error-messages":errors},model:{value:(_vm.overtimeDTO.period),callback:function ($$v) {_vm.$set(_vm.overtimeDTO, "period", $$v)},expression:"overtimeDTO.period"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.imprest.reason'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","label":_vm.$t('hr.imprest.reason'),"error-messages":errors},model:{value:(_vm.overtimeDTO.reason),callback:function ($$v) {_vm.$set(_vm.overtimeDTO, "reason", $$v)},expression:"overtimeDTO.reason"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.description'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","label":_vm.$t('global.description'),"error-messages":errors},model:{value:(_vm.overtimeDTO.description),callback:function ($$v) {_vm.$set(_vm.overtimeDTO, "description", $$v)},expression:"overtimeDTO.description"}})]}}])})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.validateAndSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }