<template>
  <app-page-layout :title="$t('time.crud.create_overtime')" icon="tw-leave-process" @close="leavePage">
    <template #content>
      <v-card flat :disabled="loading">
        <validation-observer ref="form">
          <v-flex xs12>
            <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-bottom="45" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <validation-provider :name="$t('time.overtime_request.date')" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="beginDate"
                    clearable
                    :label="$t('time.overtime_request.date')"
                    :error-messages="errors"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="beginDate = null"
                  />
                </validation-provider>
              </template>
              <v-date-picker v-model="beginDate" @change="dateMenu = false" />
            </v-menu>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('time.overtime_request.workplan')" rules="required" v-slot="{ errors }">
              <workplan-picker v-model="overtimeDTO.workplan" :date="beginDate" :error-messages="errors" />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider
              :name="$t('time.overtime_request.overtime_in_minutes')"
              rules="required|numeric|min_value:10"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model.number="overtimeDTO.overtimeInMinutes"
                clearable
                type="number"
                :min="10"
                :label="$t('time.overtime_request.overtime_in_minutes')"
                :error-messages="errors"
                :suffix="$t('time.overtime_request.short_minute')"
              />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('time.overtime_request.period')" rules="required" v-slot="{ errors }">
              <overtime-period-picker v-model="overtimeDTO.period" :error-messages="errors" />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('hr.imprest.reason')" rules="max:512" v-slot="{ errors }">
              <v-text-field
                v-model="overtimeDTO.reason"
                clearable
                :label="$t('hr.imprest.reason')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('global.description')" rules="max:512" v-slot="{ errors }">
              <v-text-field
                v-model="overtimeDTO.description"
                clearable
                :label="$t('global.description')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-flex>
        </validation-observer>
      </v-card>
    </template>
    <template #footer>
      <app-page-footer>
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import Overtime from "../model/Overtime";

  export default {
    name: "OvertimeRequestCreate",
    components: {
      "workplan-picker": () => import("../components/workplan-picker"),
      "overtime-period-picker": () => import("../components/overtime-period-picker"),
      "app-page-footer": () => import("@/layouts/app-page-footer")
    },
    data: () => ({
      loading: false,
      dateMenu: false,
      beginDate: null,
      overtimeDTO: new Overtime()
    }),

    methods: {
      leavePage() {
        this.$router.replace({ name: "overtime_request" });
      },
      validateAndSave() {
        this.$refs.form.validate().then(valid => {
          if (valid) {
            this.loading = true;
            this.$api.overtimeService
              .save(this.overtimeDTO)
              .then(({ data }) => {
                if (!data.error) {
                  this.$emit("success:save");
                  this.leavePage();
                }
              })
              .catch(e => {
                this.$helpers.showNotification(e.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    }
  };
</script>

<style scoped></style>
